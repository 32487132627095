<template>
  <v-container fluid justify-space-between class="mt-0 pt-0 mx-0 px-0">
    <v-layout row pb-5 wrap class="ma-0 pa-0">
      <v-flex xs12 mb-0>
        <v-sheet height="auto">
          <v-calendar
            :weekdays="[5, 6, 0]"
            :now="getToday"
            :value="getToday"
            v-model="start"
            color="primary"
            ref="calendar"
            :type="type"
            :locale="en-uk"
            :short-weekdays="true"
            :short-months="true"
            :show-month-on-first="false"
            :hide-header="false"
          >
            <template v-slot:day="{ date }">
              <template v-for="event in eventsMap[date]">
                <v-menu
                  :key="event.title"
                  v-model="event.open"
                  full-width
                  offset-x
                >
                  <template v-slot:activator="{ on }">
                    <div
                      v-if="!event.time"
                      v-ripple
                      class="my-event primary"
                      v-bind:class="event.mlmweekend"
                      v-on="on"
                      v-html="event.title"
                    ></div>
                  </template>
                  <v-card min-width="350px" max-width="500px" flat>
                    <v-toolbar dark>
                      <v-toolbar-title v-html="event.title"></v-toolbar-title>
                    </v-toolbar>
                    <v-card-title primary-title class="pa-0 ma-0">
                      <v-container wrap class="pa-0 ma-0">
                        <v-layout column>
                          <v-flex xs12>
                            <v-list two-line v-show="!event.hideplace">
                              <v-list-tile icon>
                                <v-icon large class="mr-4 ml-1"
                                  >mdi-map-marker</v-icon
                                >
                                <v-list-tile-content>
                                  <v-list-tile-title>
                                    <a :href="event.map" target="_blank">{{
                                      event.place1
                                    }}</a>
                                  </v-list-tile-title>
                                  <v-list-tile-sub-title class="text-xs-left">{{
                                    event.place2
                                  }}</v-list-tile-sub-title>
                                </v-list-tile-content>
                              </v-list-tile>
                            </v-list>
                          </v-flex>
                          <v-flex xs12>
                            <v-list two-line fill-width>
                              <v-list-tile icon>
                                <v-icon large class="mr-4 ml-1"
                                  >mdi-calendar-range</v-icon
                                >
                                <v-list-tile-content>
                                  <v-list-tile-title>
                                    {{ event.date1 }}
                                  </v-list-tile-title>
                                  <v-list-tile-sub-title class="text-xs-left">{{
                                    event.date2
                                  }}</v-list-tile-sub-title>
                                </v-list-tile-content>
                              </v-list-tile>
                            </v-list>
                          </v-flex>

                          <v-flex class="mx-4">
                            <span
                              v-html="event.details"
                              class="grey--text"
                            ></span>
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </v-card-title>
                    <v-card-actions>
                      <v-btn
                        flat
                        v-show="event.hideplace"
                        class="ma-2 yellow--text title"
                        large
                        to="/weekend-events"
                        >See events</v-btn
                      >
                      <!-- Change to "/weekend-events" and See events -->
                      <v-spacer></v-spacer>
                      <v-btn flat class="ma-2">Close</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </template>
            </template>
          </v-calendar>
        </v-sheet>
      </v-flex>
      <v-btn
        class="primary grey--text text--darken-4"
        @click="$refs.calendar.prev()"
      >
        <v-icon dark left>mdi-arrow-left</v-icon>Prev
      </v-btn>
      <v-spacer/>
      <v-text class="headline font-weight-light mt-1 pt-1">
      {{monthLabel}}
      </v-text>
      <v-spacer/>
      <v-btn
        class="primary grey--text text--darken-4"
        @click="$refs.calendar.next()"
      >
        Next
        <v-icon right dark>mdi-arrow-right</v-icon>
      </v-btn>
        <v-flex xs12 mb-3 mt-1>
        <v-card class="ma-0 pa-0">
          <v-card-text class="text-xs-center text-sm-left grey--text">
            <div>
              We've created a calendar with local leather friendly events.
            </div>

            <div class="mt-1">
              Please tell us on our
              <a
                href="https://www.facebook.com/groups/manchesterleather/"
                style="color:#fff"
                target="_blank"
              >
                <strong>Facebook</strong>
              </a>
              if we've missed something.
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { db } from "@/fb";
export default {
  metaInfo: {
    titleTemplate: "%s | What's on",
    link: [
      {
        rel: "canonical",
        href: "https://www.manchesterleathermen.com/calendar",
      },
    ],
    meta: [
      { itemprop: "name", content: "What's on ← Manchester Leathermen " },
      {
        itemprop: "description",
        content: "See our calendar of upcoming Manchester Leather Events.",
      },
    ],
  },
  data: () => ({
    type: "month",
    weekdays: "[5,6,0]",
    dbEvents: [],
    dbClubs: [],
    start: "{ today }",
  }),
  computed: {
    // convert the list of events into a map of lists keyed by date
    eventsMap() {
      const map = {};
      this.dbEvents.forEach((e) => (map[e.date] = map[e.date] || []).push(e));
      return map;
    },
    getToday() {
      let date = new Date();
      var dd = String(date.getDate()).padStart(2, "0");
      var mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = date.getFullYear();
      var today = yyyy + "-" + mm + "-" + dd;      
      return today;
    },
    monthLabel() {
      if (this.start === "{ today }") {
        return ""
      } else if (this.start.substring(5,6) == "1") {
        return this.start.substring(5,7) + "/" + this.start.substring(0,4)
      } else {
        return this.start.substring(6,7) + "/" + this.start.substring(0,4)
      }
    },
  },
  methods: {
    open(event) {
      alert(event.title);
    },
  },
  created() {
    // fetch data from the db
    db.collection("clubs")
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          let club = doc.data();
          club.id = doc.id;
          this.dbClubs.push(club);
        });
      });
    db.collection("events")
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          let event = doc.data();
          event.id = doc.id;
          event.open = false;
          let date = new Date(event.date);
          let day = date.getDay();
          switch (day) {
            case 5:
              day = "Friday";
              break;
            case 6:
              day = "Saturday";
              break;
            case 0:
              day = "Sunday";
              break;
            default:
              day = "";
          }

          let day1 = date.getDate();
          switch (day1) {
            case 1:
              day1 = "1st";
              break;
            case 2:
              day1 = "2nd";
              break;
            case 3:
              day1 = "3rd";
              break;
            default:
              day1 = day1 + "th";
          }

          let month = date.getMonth();
          switch (month) {
            case 0:
              month = "January";
              break;
            case 1:
              month = "February";
              break;
            case 2:
              month = "March";
              break;
            case 3:
              month = "April";
              break;
            case 4:
              month = "May";
              break;
            case 5:
              month = "June";
              break;
            case 6:
              month = "July";
              break;
            case 7:
              month = "August";
              break;
            case 8:
              month = "September";
              break;
            case 9:
              month = "October";
              break;
            case 10:
              month = "November";
              break;
            case 11:
              month = "December";
              break;
            default:
              month = "";
          }
          event.date1 = day + " " + day1 + " of " + month;
          this.dbClubs.forEach((club) => {
            if (club.id === event.place) {
              event.place1 = club.place1;
              event.place2 = club.place2;
              event.map = club.map;
            }
          });
          this.dbEvents.push(event);
        });
      });
  },
};
</script>
<style>
.my-event {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 2px;
  /* background-color: #BBDEFB; */
  font-weight: 500;
  color: #000000;
  width: 100%;
  font-size: 13px;
  padding: 3px;
  cursor: pointer;
  margin-bottom: 1px;
}
</style>
